export interface AokSession {
  idkitAccessToken: string;
  databaseUserId: string;
}

export enum IdKitStatus {
  CompletedPassed = 'COMPLETED_PASS',
  CompletedFlagged = 'COMPLETED_FLAGGED',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  InProgress = 'INPROGRESS',
  Submitted = 'SUBMITTED',
}
